import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainServiceService } from 'src/app/services/main-service.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaginaSaleService {

  constructor(
    private http: HttpClient,
    private mainService: MainServiceService
  ) { }

  getElenco(): Observable <any> {
    const url = this.mainService.SERVER_URL + `&action=getElencoSale&lang=` + this.mainService.language + "&idSection=" + (this.mainService.sectionId);
    return this.http.get<any>(url)
  }
}
