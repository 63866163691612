import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ElencoGenericoService } from '../elenco-generico.service';
import { Router } from '@angular/router';
import { MainServiceService } from 'src/app/services/main-service.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-dettaglio',
  templateUrl: './dettaglio.component.html',
  styleUrls: ['./dettaglio.component.css']
})
export class DettaglioComponent implements OnInit {
  myItem: any;
  traduzioni: any;

  constructor(
    private service: ElencoGenericoService,
    private router: Router,
    public mainService: MainServiceService,

  ) { }

  ngOnInit(): void {
    this.mainService.showLoader(true);

    forkJoin([
      this.service.getDettaglio(this.mainService.detailId),
      this.mainService.getTraductionsSubject().pipe(first(data => data != null))
    ])
    .subscribe(
      ([item, traduzioni]) => {
        this.traduzioni = traduzioni;

        this.myItem = item;
        this.mainService.showLoader(false);
      });
  }

}
