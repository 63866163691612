<div class="shop-area pt-75 pb-80 gray-bg-4 container-padding-res shop-elenco">
  <div class="container">
    <div class="shop-area-wrapper pro-col-40">
      <div class="row grid" data-show="9" data-load="4">

        <div class="col-lg-4 col-md-6 item-hidden grid-item" *ngFor="let item of arrayItems">
          <div class="shop-wrap shop-shadow mb-40">
            <div class="shop-img">
              <a class="" [routerLink]="[item.routerLink]">
                <img src="{{item.immagine}}" alt="">
              </a>
            </div>
            <div class="shop-content-2">
              <div class="shop-name">
                <h4>
                  <a class="" [routerLink]="[item.routerLink]">{{item.titolo}}</a>
                </h4>
                <div class="shop-price">
                  <span>€ {{item.prezzo}}</span>
                </div>
              </div>
            </div>
            <div class="shop-price shop-details">
              <span class=""><a class="" [routerLink]="[item.routerLink]"><i class="ti-angle-double-right"></i>Scopri i dettagli</a></span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
