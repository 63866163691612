import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { MShopElencoComponent } from './pages/m-ticket-m-shop/m-shop/m-shop-elenco/m-shop-elenco.component';
import { MShopSchedaComponent } from './pages/m-ticket-m-shop/m-shop/m-shop-scheda/m-shop-scheda.component';
import { MTicketComponent } from './pages/m-ticket-m-shop/m-ticket/m-ticket.component';
import { GalleriaFotoComponent } from './pages/multimedia/galleria-foto/galleria-foto.component';
import { GalleriaVideoComponent } from './pages/multimedia/galleria-video/galleria-video.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PaginaGenericaComponent } from './pages/pagina-generica/pagina-generica.component';
import { MTicketQrCodeComponent } from './pages/m-ticket-m-shop/m-ticket/m-ticket-qr-code/m-ticket-qr-code.component';
import { ElencoComponent } from './pages/elenco-generico/elenco/elenco.component';
import { DettaglioComponent } from './pages/elenco-generico/dettaglio/dettaglio.component';
import { ElencoAccoglienzaComponent } from './pages/accoglienza-ricettivita/elenco-accoglienza/elenco-accoglienza.component';
import { DettaglioAccoglienzaComponent } from './pages/accoglienza-ricettivita/dettaglio-accoglienza/dettaglio-accoglienza.component';
import { ElencoEventiCorsiComponent } from './pages/eventi-corsi/elenco-eventi-corsi/elenco-eventi-corsi.component';
import { DettaglioEventiCorsiComponent } from './pages/eventi-corsi/dettaglio-eventi-corsi/dettaglio-eventi-corsi.component';
import { ContattiComponent } from './pages/contatti/contatti.component';
import { PaginaSaleComponent } from './pages/pagina-sale/pagina-sale.component';
import { PaginaConvenzioniComponent } from './pages/pagina-convenzioni/pagina-convenzioni.component';
import { MTicketTempComponent } from './pages/m-ticket-m-shop/m-ticket/m-ticket-temp/m-ticket-temp.component';

const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: ':lang/0/:sectionName', component: HomePageComponent },
  { path: ':lang/3/:sectionName', component: PaginaGenericaComponent }, // Termini e condizioni
  { path: ':lang/4/:sectionName', component: PaginaGenericaComponent }, // Privacy
  { path: ':lang/5/:sectionName', component: PaginaGenericaComponent }, // Credits
  { path: ':lang/6/:sectionName', component: PaginaGenericaComponent }, // Regolamento
  { path: ':lang/7/:sectionName', component: PaginaGenericaComponent }, // Carta servizi
  { path: ':lang/13/:sectionName', component: ContattiComponent }, // Contatti
  { path: ':lang/16/:sectionName', component: PaginaGenericaComponent }, // La storia
  { path: ':lang/17/:sectionName', component: PaginaGenericaComponent }, // Accademia dei Lincei
  { path: ':lang/18/:sectionName', component: PaginaSaleComponent }, // Sale piano terra
  { path: ':lang/19/:sectionName', component: PaginaSaleComponent }, // Sale piano nobile
  { path: ':lang/21/:sectionName', component: PaginaGenericaComponent }, // Sala immersiva
  { path: ':lang/22/:sectionName', component: PaginaGenericaComponent }, // Museo delle Api e del Miele
  { path: ':lang/23/:sectionName', component: PaginaConvenzioniComponent }, // Partner & Convenzioni
  { path: ':lang/24/:sectionName', component: ElencoAccoglienzaComponent }, // Accoglienza e Ricettività
  { path: ':lang/24/:sectionName/:idDettaglio/:titoloDettaglio', component: DettaglioAccoglienzaComponent },
  { path: ':lang/25/:sectionName', component: ElencoComponent }, // Cosa fare nei dintorni
  { path: ':lang/25/:sectionName/:idDettaglio/:titoloDettaglio', component: DettaglioComponent },
  { path: ':lang/26/:sectionName', component: ElencoComponent }, // Eventi e Corsi di Formazione
  { path: ':lang/26/:sectionName/:idDettaglio/:titoloDettaglio', component: DettaglioComponent },
  { path: ':lang/27/:sectionName', component: PaginaGenericaComponent }, // Welfare Culturale e Inclusività
  { path: ':lang/28/:sectionName', component: PaginaGenericaComponent }, // Umbria Culture For Family
  { path: ':lang/29/:sectionName', component: PaginaGenericaComponent }, // Divulgazione scientifica
  { path: ':lang/30/:sectionName', component: PaginaGenericaComponent }, // Didattica
  { path: ':lang/31/:sectionName', component: PaginaGenericaComponent }, // Visite ed esperienze
  { path: ':lang/32/:sectionName', component: MTicketComponent }, // mTicket
//  { path: ':lang/32/:sectionName', component: MTicketTempComponent }, // mTicket
  { path: ':lang/32/:sectionName/qrcode', component: MTicketQrCodeComponent }, // qrCode recover
  { path: ':lang/32/:sectionName/qrcode/:email/:codicePrenotazione', component: MTicketQrCodeComponent }, // qrCode recover
  { path: ':lang/33/:sectionName', component: PaginaGenericaComponent }, // Bookshop temporaneo
//  { path: ':lang/33/:sectionName', component: MShopElencoComponent },
//  { path: ':lang/33/:sectionName/:idDettaglio/:titoloDettaglio', component: MShopSchedaComponent },
  { path: ':lang/37/:sectionName', component: PaginaGenericaComponent }, // Museo esperenziale
  { path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabled'
  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
