import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
declare let $: any;

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {
  @Input() items: any[];

  customOptions: OwlOptions = {};

  constructor() {
    this.customOptions = {
      loop: true,
      nav: false,
      autoplay: true,
      autoplayTimeout: 10000,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn',
      dots: false,
      responsive: {
          0: {
              items: 1
          },
          768: {
              items: 1
          },
          1000: {
              items: 1
          }
      }
    }
  }

  ngOnInit(): void {
  }
}
