import { Component, OnInit, ViewChild } from '@angular/core';
import { MainServiceService } from 'src/app/services/main-service.service';
import { forkJoin } from 'rxjs';
import { MultimediaService } from '../multimedia.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-galleria-video',
  templateUrl: './galleria-video.component.html',
  styleUrls: ['./galleria-video.component.css']
})
export class GalleriaVideoComponent implements OnInit {
  arrayItems: any[];
  traduzioni: any;

  constructor(
    private router: Router,
    private service: MultimediaService,
    public mainService: MainServiceService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };
  }

  ngOnInit(): void {
    forkJoin([
      this.service.getElencoVideo(),
      this.mainService.getTraductionsSubject().pipe(first(data => data != null))
    ])
    .subscribe(
      ([items, traduzioni]) => {
        this.traduzioni = traduzioni;
        for(let i=0; i < items.length; i++) {
          items[i].videoUrl = this.mainService.sanitizeURL('https://www.youtube.com/embed/' + items[i].videoCode);
        }
        this.arrayItems = items;
        this.mainService.showLoader(false);
     });

  }

}
