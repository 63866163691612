import { Injectable } from '@angular/core';
import 'sweetalert2/src/sweetalert2.scss';

declare const $: any;
declare var require: any;

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor() { }

  show(notificationType: string, titolo = '', message = '') {
    const Swal = require('sweetalert2');

    switch (notificationType) {
      case 'error':
        if (!titolo) {
          titolo = 'Si è verificato un problema';
        }
        Swal.fire({
          title: titolo,
          html: message,
          buttonsStyling: true,
          icon: 'error'
        });
        break;

      case 'warning':
        if (!titolo) {
          titolo = 'ATTENZIONE';
        }
        Swal.fire({
          title: titolo,
          html: message,
          buttonsStyling: true,
          icon: 'warning'
        });
        break;


        case 'info':
          if (!titolo) {
            titolo = 'Informazione';
          }
          Swal.fire({
            title: titolo,
            html: message,
            buttonsStyling: true,
            icon: 'warning'
          });
          break;

      case 'success':
        Swal.fire({
          title: titolo,
          html: message,
          buttonsStyling: true,
          icon: 'success'
        });
        break;

      case 'not_OpOk':
        $.notify({
          message: 'Operazione completata con successo'
      }, {
          type: 'success',
          timer: 3000,
          placement: {
              from: 'top',
              align: 'center'
          },
          // tslint:disable-next-line: max-line-length
          template: '<div data-notify="container" class="col-xs-11 col-sm-8 col-md-6 col-lg-4 alert alert-{0} alert-with-icon" role="alert">' +
            // tslint:disable-next-line: max-line-length
            '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
            '<i class="material-icons" data-notify="icon">done_all</i> ' +
            '<span data-notify="title">{1}</span> ' +
            '<span data-notify="message">{2}</span>' +
            '<div class="progress" data-notify="progressbar">' +
              // tslint:disable-next-line: max-line-length
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
            '</div>' +
            '<a href="{3}" target="{4}" data-notify="url"></a>' +
          '</div>'
      });
        break;


        case 'not_noUpdate':
          $.notify({
            message: 'La tua webapp è aggiornata all\'ultima versione'
        }, {
            type: 'info',
            timer: 3000,
            placement: {
                from: 'top',
                align: 'center'
            },
            // tslint:disable-next-line: max-line-length
            template: '<div data-notify="container" class="col-xs-11 col-sm-8 col-md-6 col-lg-4 alert alert-{0} alert-with-icon" role="alert">' +
              // tslint:disable-next-line: max-line-length
              '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
              '<i class="material-icons" data-notify="icon">done_all</i> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
                // tslint:disable-next-line: max-line-length
                '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              '</div>' +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
            '</div>'
        });
          break;
    }
  }
}
