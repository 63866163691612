<footer class="footer-area theme-bg pt-50 pb-30" *ngIf="configuration?.footer">
  <div class="container">
      <div class="footer-top border-bottom-1">
          <div class="row">
              <div class="col-12">
                  <div class="support-text text-center">
                      <h5 class="h5-custom"><i>Euromedia s.r.l. è stata cofinanziata dalla Regione Umbria, nell’ambito del Bando Sostegno Progetti Imprese Culturali e Creative 2020</i></h5>
                      <h5 class="h5-custom"><i>Euromedia s.r.l. was co-financed by Regione Umbria, with the Call for the support of Cultural and Creative Companies Projects 2020</i></h5>
                      <img class="img-fluid mt-30" src="assets/img/loghi-footer-w.png" alt="">
                  </div>
              </div>
          </div>
      </div>
      <div class="footer-middle pt-50">
          <div class="row">
              <div class="col-lg-3 col-md-6 col-sm-5">
                  <div class="footer-widget mb-40">
                      <a [routerLink]="'/'"><img class="img-fluid" src="assets/img/logo-W.png" alt=""></a>
                      <div class="footer-social">
                          <ul>
                            <li *ngFor="let social of configuration.social">
                              <a href="{{social.url}}" target="_blank">
                                <i class="ti-{{social.iconAlt}}"></i>
                              </a>
                            </li>
                          </ul>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-7">
                  <div class="footer-widget mb-40">
                      <div class="footer-title mb-20">
                          <h4>Palazzo Cesi</h4>
                      </div>
                      <div class="footer-sub-menu">
                          <ul>
                              <li *ngFor="let idSezione of menuPalazzoCesi">
                                <a [routerLink]="['/' + mainService.language + '/' + idSezione + '/' + traduzioni?.sezioni[idSezione].titoloUrl]"><i class="ti-angle-right"></i><span [innerHTML]="traduzioni?.sezioni[idSezione].denominazione"></span></a>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6">
                  <div class="footer-widget mb-40">
                      <div class="footer-title mb-20">
                          <h4>Aperture</h4>
                      </div>
                      <div>
                          <p [innerHTML]="configuration.footer.orariApertura">
                          </p>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="footer-widget mb-40">
                    <div class="footer-title mb-20">
                        <h4>Scarica la app</h4>
                    </div>
                    <div>
                      <a [routerLink]="'/' + mainService.language + '/37/' + traduzioni?.sezioni[37].titoloUrl" >
                        <img src="{{configuration.footer.downloadApp.immagine}}" width="100%">
                      </a>
                    </div>
                    <div class="row mt-15">
                      <div class="col-6" *ngIf="configuration.footer.downloadApp.link_android != ''">
                        <div class="boxBianco">
                          <a [href]="configuration.footer.downloadApp.link_android" target="_blank">
                            <img src="assets/img/img_link_play_store.png" width="100%">
                          </a>
                        </div>
                      </div>
                      <div class="col-6" *ngIf="configuration.footer.downloadApp.link_ios != ''">
                        <div class="boxBianco">
                          <a [href]="configuration.footer.downloadApp.link_ios" target="_blank">
                            <img src="assets/img/img_link_app_store.png" width="100%">
                          </a>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="row">
          <div class="footer-wrap">
              <div class="footer-menu footer-link">
                  <nav>
                      <ul>
                          <li *ngFor="let secondaryMenuItem of mainService.traductions?.menuSecondario; first as isFirst">
                              <ng-container *ngIf="secondaryMenuItem.url; else noExternalUrl">
                                  <a [href]="secondaryMenuItem.url" target="_blank" rel="noopener">{{secondaryMenuItem.denominazione}}</a>
                              </ng-container>
                              <ng-template #noExternalUrl>
                                <ng-container *ngIf="secondaryMenuItem.id != 97; else isCookiePolicy">
                                  <a [routerLink]="'/' + mainService.language + '/' + secondaryMenuItem.id + '/' + secondaryMenuItem.titoloUrl" >{{secondaryMenuItem.denominazione}}</a>
                                </ng-container>
                                <ng-template #isCookiePolicy>
                                  <a href="javascript:void(0)" onclick="cookieconsent.openPreferencesCenter()" >{{secondaryMenuItem.denominazione}}</a>
                                </ng-template>
                              </ng-template>
                        </li>
                      </ul>
                  </nav>
              </div>
          </div>
      </div>
      <div class="footer-bottom pt-35">
          <div class="row">
              <div class="col-sm-12">
                  <div class="copyright text-center">
                      <p>
                        Copyright {{year}} © <a href="https://www.euromediaitalia.com/" target="_blank">Euromedia</a> | Partita IVA: 00652940552 | Iscrizione REA Terni: n.66561 | All Rights Reserved | Credits <a href="https://www.dreamfactorydesign.it/" target="_blank">Dream Factory design</a>
                      </p>
                  </div>
              </div>
          </div>
      </div>
  </div>
</footer>
