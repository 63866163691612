import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxImageGalleryModule } from 'ngx-image-gallery';
import { PaginaSaleComponent } from './pagina-sale.component';



@NgModule({
  declarations: [
    PaginaSaleComponent
  ],
  imports: [
    CommonModule,
    NgxImageGalleryModule
  ]
})
export class PaginaSaleModule { }
