import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePageComponent } from './home-page.component';
import { SliderComponent } from './slider/slider.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RouterModule } from '@angular/router';
import { NgxImageGalleryModule } from 'ngx-image-gallery';


@NgModule({
  declarations: [HomePageComponent, SliderComponent],
  imports: [
    CommonModule,
    CarouselModule,
    RouterModule,
    NgxImageGalleryModule
  ],
  exports: [
    HomePageComponent
  ]})
export class HomePageModule { }
