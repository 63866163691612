import { Component, OnInit } from '@angular/core';
import { MainServiceService } from 'src/app/services/main-service.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  configuration: any;
  traduzioni: any;
  year: number;
  menuPalazzoCesi: number[] = [13,32,26,23];

  constructor(
    public mainService: MainServiceService
  ) {
    this.year = new Date().getFullYear();
  }

  ngOnInit(): void {
    this.mainService.getConfigurationSubject().subscribe(data => {
      if (data) {
        this.configuration = data;
      }
    });

    this.mainService.getTraductionsSubject().subscribe(data => {
      if (data !== null) {
        this.traduzioni = data;
      }
    });
  }

}
