import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElencoComponent } from './elenco/elenco.component';
import { DettaglioComponent } from './dettaglio/dettaglio.component';
import { RouterModule } from '@angular/router';
import { LayoutPaginaDettaglioModule } from 'src/app/modules/layout-pagina-dettaglio/layout-pagina-dettaglio.module';



@NgModule({
  declarations: [ElencoComponent, DettaglioComponent],
  imports: [
    CommonModule,
    RouterModule,
    LayoutPaginaDettaglioModule
  ]
})
export class ElencoGenericoModule { }
