import { Component, OnInit, ViewChild } from '@angular/core';
import { PaginaSaleService } from './pagina-sale.service';
import { MainServiceService } from 'src/app/services/main-service.service';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
import { NgxImageGalleryComponent, GALLERY_IMAGE, GALLERY_CONF } from "ngx-image-gallery";

@Component({
  selector: 'app-pagina-sale',
  templateUrl: './pagina-sale.component.html',
  styleUrls: ['./pagina-sale.component.css']
})
export class PaginaSaleComponent implements OnInit {
  @ViewChild(NgxImageGalleryComponent) ngxImageGallery: NgxImageGalleryComponent;

  arrayItems: any[];
  traduzioni: any;

  conf: GALLERY_CONF = {
    imageOffset: '0px',
    showDeleteControl: false,
    showImageTitle: false,
  };

  images: GALLERY_IMAGE[] = [];

  constructor(
    private service: PaginaSaleService,
    public mainService: MainServiceService,

  ) { }

  ngOnInit(): void {
    this.mainService.showLoader(true);

    forkJoin([
      this.service.getElenco(),
      this.mainService.getTraductionsSubject().pipe(first(data => data != null))
    ])
    .subscribe(
      ([data, traduzioni]) => {
        this.traduzioni = traduzioni;

        this.arrayItems = data.items;
        this.mainService.showLoader(false);
     });
  }

  openGallery(idItem) {
    this.images = [];

    this.images.push({url: this.arrayItems[idItem].immagine});
    if (this.arrayItems[idItem].gallery.length > 0) {
      for(let i=0; i < this.arrayItems[idItem].gallery.length; i++) {
        this.images.push({url: this.arrayItems[idItem].gallery[i].url});
      }
    }
    let that = this;
    setTimeout(function(){that.ngxImageGallery.open(0)}, 100);
    ;
  }
}
