import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginaConvenzioniComponent } from './pagina-convenzioni.component';
import { NgxImageGalleryModule } from 'ngx-image-gallery';



@NgModule({
  declarations: [
    PaginaConvenzioniComponent,
  ],
  imports: [
    CommonModule,
    NgxImageGalleryModule
  ]
})
export class PaginaConvenzioniModule { }
