<div *ngIf="homePage" class="pb-70 homePage">
<app-slider *ngIf="homePage?.slider"
  [items]="homePage.slider"
></app-slider>


<!-- area-1= BIGLIETTI E ORARI  -->
<div class="works-area-2  pt-70 pb-70 area-1">
  <div class="container">
      <div class="row">
          <div class="col-lg-6 col-md-6 mb-60-mobile">
              <div class="works-content-2">
                  <div class="works-title-2 tools-content">
                      <h2>{{traduzioni?.sezioni[15].denominazione}}</h2>
                  </div>
                  <div class="row">
                      <div class="col-sm-12">
                          <div class="works-feature mt-30" [innerHTML]="homePage.tariffe">
                          </div>
                      </div>

                  </div>
              </div>
          </div>
          <div class="col-lg-6 col-md-6">
              <div class="works-content-2">
                  <div class="works-title-2 tools-content">
                      <h2>{{traduzioni?.sezioni[14].denominazione}}</h2>
                  </div>
                  <div class="row">
                      <div class="col-sm-12">
                          <div class="works-feature mt-30" [innerHTML]="homePage.orariApertura">
                          </div>
                      </div>

                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<!-- area-2= PERCORSI DI VISITA ED ESPERIENZE  -->
<div class="tools-area pt-70 pb-50 mt-30 area-2" *ngIf="homePage.percorsiEsperienze?.items.length > 0">
  <div class="container">
      <div class="">
          <div class="row d-flex">
              <div class="col-lg-5 col-md-6 pb-30">
                  <div class="tools-content">
                      <h2 [innerHTML]="homePage.percorsiEsperienze.titolo"></h2>
                      <p class="pt-30" [innerHTML]="homePage.percorsiEsperienze.testo"></p>
                      <div class="tools-btn mt-45">
                        <a class="btn-hover default-btn" [routerLink]="'/' + mainService.language + '/31/' + traduzioni?.sezioni[31].titoloUrl">Leggi tutto</a>
                      </div>
                  </div>
              </div>
              <div class="col-lg-7 col-md-6">
                  <div class="row">
                      <div class="col-sm-6 col-12" *ngFor="let item of homePage.percorsiEsperienze.items">
                          <a [routerLink]="item.routerLink">
                              <div class="blog-wrap-2 mb-50 zoom-hover">
                                  <div class="blog-img">
                                      <img [src]="item.immagine" alt="">
                                  </div>
                                  <div class="blog-conent-2" style="margin-top: 5px;">
                                      <h3 [innerHTML]="item.titoloMostrato"></h3>
                                  </div>
                              </div>
                          </a>
                      </div>
                   </div>
              </div>
          </div>

      </div>
  </div>
</div>
<!-- area-3= NEWS  -->
<div class="blog-area pt-70 blog-area-h3 p-elenco area-3" *ngIf="homePage.news?.items.length > 0">
  <div class="container">
      <div class="text-center mb-60">
          <h2>News</h2>
      </div>
      <div class="row">
          <div class="col-md-4 col-12" *ngFor="let item of homePage.news.items">
            <div class="blog-wrap-2 mb-50 zoom-hover">
                <div class="blog-img">
                  <img [src]="item.immagine" alt="">
                </div>
                <div class="blog-conent-2">
                    <div class="blog-meta-2">
                      {{item.categoria}}
                    </div>
                    <h3>{{item.titolo}}</h3>
                    <div class="tools-btn mt-45">
                      <a class="btn-hover default-btn" [routerLink]="'/' + mainService.language + '/26/' + traduzioni?.sezioni[26].titoloUrl + '/' + item.id+ '/' + item.titoloUrl">Leggi tutto</a>
                    </div>
                </div>
            </div>
          </div>
      </div>
      <div class="row">
          <div class="blog-all-btn text-center pb-30">
              <a class="vediTutti" [routerLink]="'/' + mainService.language + '/26/' + traduzioni?.sezioni[26].titoloUrl">Vedi tutti gli Eventi<i class="ti-arrow-circle-right"></i></a>
          </div>
      </div>
  </div>
</div>

<!-- area-4= PHOTOGALLERY  -->
<div class="photogallery-area pt-35 pb-35 mt-30 area-4" *ngIf="homePage.photogallery.preview.length > 0">
    <div class="container">
        <div class="row">
            <div class="col-12">
              <div class="text-center mb-60">
                  <h2>Photogallery</h2>
              </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-3 col-lg-3 col-sm-6 col-12 mb-50" *ngFor="let item of homePage.photogallery.preview; let i = index">
                <img [src]="item" (click)="openGallery(i)" class="galleryPointer">
            </div>
        </div>
    </div>
    <ngx-image-gallery
    [images]="images"
    [conf]="conf"
    ></ngx-image-gallery>
</div>

  <!-- area-5= PARTNER E CONVENZIONI  -->
  <div class="tools-area pt-70 area-5">
      <div class="container">
          <div class="tools-slider-active owl-carousel">
              <div class="row d-flex">
                  <div class="col-lg-6 col-md-6">
                      <div class="tools-img">
                          <img src="{{homePage.partnerConvenzioni.immagine}}" alt="" width="100%">
                      </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                      <div class="tools-content">
                          <h2 class="mb-30" [innerHTML]="homePage.partnerConvenzioni.titolo"></h2>
                          <p class="pt-30" [innerHTML]="homePage.partnerConvenzioni.testo"></p>
                          <div class="tools-btn mt-45">
                              <a class="btn-hover default-btn" [routerLink]="'/' + mainService.language + '/23/' + traduzioni?.sezioni[23].titoloUrl">Leggi tutto</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
