import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MainServiceService } from 'src/app/services/main-service.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MShopService {

  constructor(
    private http: HttpClient,
    private mainService: MainServiceService
  ) { }

  getElenco(): Observable<any> {
    const url = this.mainService.SERVER_URL + `&action=getElencoProdotti&lang=` + this.mainService.language;
    return this.http.get<any>(url)
  }

  getDettaglio(id: number): Observable<any> {
    const url = this.mainService.SERVER_URL + `&action=getProdotto&id=` + id + `&lang=` + this.mainService.language;
    return this.http.get<any>(url)
  }

  finalizePayment(paypalInfo: any, additionalInfo: any) {
    return this.http.post<any>(this.mainService.SERVER_URL + `&action=mShopPayment`, {paypalInfo, additionalInfo})
    .pipe(map(data => {
      return data;
    }));
  }

}
