import { Component, OnInit } from '@angular/core';
import { MainServiceService } from 'src/app/services/main-service.service';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { MShopService } from '../m-shop.service';
import { take, first } from 'rxjs/operators';

@Component({
  selector: 'app-m-shop-elenco',
  templateUrl: './m-shop-elenco.component.html',
  styleUrls: ['./m-shop-elenco.component.css']
})
export class MShopElencoComponent implements OnInit {
  arrayItems: any[];
  traduzioni: any;

  constructor(
    private service: MShopService,
    private router: Router,
    public mainService: MainServiceService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };
  }

  ngOnInit(): void {
    forkJoin([
      this.service.getElenco(),
      this.mainService.getTraductionsSubject().pipe(first(data => data != null))
    ])
    .subscribe(
      ([response, traduzioni]) => {
        this.traduzioni = traduzioni;
        for(let i=0; i < response.elencoProdotti.length; i++) {
          response.elencoProdotti[i].routerLink = '/' + this.mainService.language + '/24/' + this.traduzioni.sezioni[24].titoloUrl + '/' + response.elencoProdotti[i].id + '/' + response.elencoProdotti[i].titoloUrl;
        }
        this.arrayItems = response.elencoProdotti;
        this.mainService.showLoader(false);
     });
  }
}
