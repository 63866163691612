import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainServiceService } from 'src/app/services/main-service.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MultimediaService {

  constructor(
    private http: HttpClient,
    private mainService: MainServiceService
  ) { }

  getElencoAlbum(): Observable<any[]> {
    const url = this.mainService.SERVER_URL + `&action=getElencoAlbum&lang=` + this.mainService.language;
    return this.http.get<any []>(url)
  }

  getAlbum(id: number): Observable<any> {
    const url = this.mainService.SERVER_URL + `&action=getAlbum&id=` + id + `&lang=` + this.mainService.language;
    return this.http.get<any>(url)
  }

  getElencoVideo(): Observable<any[]> {
    const url = this.mainService.SERVER_URL + `&action=getElencoVideo&lang=` + this.mainService.language;
    return this.http.get<any []>(url)
  }

}
