<div class="blog-area pt-70 blog-area-h3 p-elenco"  *ngIf="arrayItems?.length > 0">
  <div class="container">
      <div class="shop-menu-filter" *ngIf="arrayCategorie.length > 0">
          <div class="filter-active mb-50">
              <a href="#">filtra per categoria <i class="ti-arrow-down"></i></a>
              <div class="shop-filter-menu">
                  <ul>
                      <li class="filter-title">Categoria
                          <ul>
                              <li><a href="#">Albergo</a></li>
                              <li><a href="#">B&B</a></li>
                              <li><a href="#">Altro filtro</a></li>
                              <li><a href="#">Lorem ipsum</a></li>
                          </ul>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
      <div class="row">
          <div class="col-lg-4 col-md-6" *ngFor="let item of arrayItems">
            <a [routerLink]="[item.routerLink]">
              <div class="blog-wrap-2 mb-50 zoom-hover">
                  <div class="blog-img">
                      <img src="{{item.immagine}}" alt="">
                  </div>
                  <div class="blog-conent-2">
                      <div class="blog-meta-2" *ngIf="item.categoria">
                        {{item.categoria}}
                      </div>
                      <h3>{{item.titolo}}</h3>
                  </div>
              </div>
            </a>
          </div>
      </div>
  </div>
</div>
