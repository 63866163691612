<div class="portfolio-area pt-75 pb-80 container-padding-res" *ngIf="arrayItems">
   <div class="container">
      <div class="portfolio-style pro-col-40">
        <div class="row grid">
            <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 grid-item" *ngFor="let item of arrayItems">
                <div class="portfolio-wrap mb-32">
                    <div class="embed-responsive">
                      <iframe class="embed-responsive-item" [src]="item.videoUrl" allowfullscreen></iframe>
                    </div>
                    <div class="portfolio-content">
                        <div class="portfolio-title">
                            <h4>{{item.titolo}}</h4>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
    </div>
</div>
