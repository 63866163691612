<div class="single-product-area pt-80 pb-80" *ngIf="myItem">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="product-details-img pr-10">
          <img class="zoompro" src="{{myItem.immagine}}" data-zoom-image="{{myItem.immagine}}" alt="zoom"/>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="product-details-content pl-10">
          <h2>{{myItem.titolo}}</h2>
          <h2 class="prezzo">€ {{myItem.prezzoTxt}}</h2>
          <div class="pro-details-quality mt-45" [innerHTML]="traduzioni.quantitaRichiesta + ':'">

          </div>
          <div class="pro-details-quality mb-30">
            <div class="cart-plus-minus">
              <input class="cart-plus-minus-box" type="number" name="qtybutton" (keyup)="onChangeQuantity($event)" (change)="onChangeQuantity($event)" value="1">
            </div>
          </div>
          <div *ngIf="quantitaValida; else invalidValue" class="divImporti">
            <div>
              <b>{{traduzioni.totaleProdotto}}:</b> € {{totaleProdotto}}
            </div>
            <div>
              <b>{{traduzioni.speseSpedizione}}:</b> € {{speseSpedizione}}
            </div>
            <div>
              <b>{{traduzioni.importoTotale}}:</b> € {{importoTotale}}
            </div>
            <div class="row mt-4"  *ngIf="buttonReady">
              <div class="col-md-9 col-sm-12 col-xs-12 m-t">
                 <ngx-paypal [config]="payPalConfig"></ngx-paypal>
               </div>
              </div>
          </div>
          <ng-template #invalidValue>
            <div>
              {{traduzioni.inserireQuantitaValida}}
            </div>
          </ng-template>

        </div>
      </div>
    </div>
  </div>
</div>
<div class="description-review-area pb-70" *ngIf="0">
  <div class="container">
    <div class="description-review-wrapper">
      <div class="description-review-topbar nav">
        <a class="active" data-bs-toggle="tab" href="#des-details1">Descrizione</a>
        <a data-bs-toggle="tab" href="#des-details2">Ulteriori informazioni</a>
      </div>
      <div class="tab-content description-review-bottom">
        <div id="des-details1" class="tab-pane active">
          <div class="product-description-wrapper">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas pulvinar massa metus, vitae pharetra lacus sodales sit amet. Morbi accumsan suscipit lacus, sit amet egestas magna elemen tum nec. Mauris urna enim, rutrum in iaculis nec, vehicula ut libero. Etiam sit amet ex orci. Duis eget consectetur libero, eget interdum metus. Aliquam rhoncus porttitor felis, a tincidunt ex scel erisque et. Morbi faucibus venenatis dignissim. Nullam ut facilisis mauris. In hac habitasse platea dictumst. </p>
            <p>Pellentesque luctus augue ipsum, ut tincidunt odio tempus at. Nullam ac quam venenatis, bibendum eros at, placerat risus. Maecenas cursus elit non nisl finibus congue. Donec posuere fringilla ante eu vehicula. Fusce sed erat quis nisi gravida vehicula id vitae dolor. In at libero pretium, maximus lorem vitae, pharetra turpis feugiat facilisis ullamcorper.  </p>
          </div>
        </div>
        <div id="des-details2" class="tab-pane ">
          <div class="product-anotherinfo-wrapper">
            <ul>
              <li><span>Weight</span> 400 g</li>
              <li><span>Dimensions</span>10 x 10 x 15 cm </li>
              <li><span>Materials</span> 60% cotton, 40% polyester</li>
              <li><span>Other Info</span> American heirloom jean shorts pug seitan letterpress</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="related-product mb-75" *ngIf="0">
  <div class="container">
    <div class="related-product-title text-center mb-25">
      <h4>Continua gli acquisti</h4>
    </div>
    <div class="related-product-active owl-carousel">

      <?php
        $titoloBoxAltri = "Integer tincidunt, odio id cursus viverra, elit nulla pharetra dui, condimentum aliquam leo mi viverra lorem.";
        require("box-elenco-altri.php");
      ?>

      <?php
        $titoloBoxAltri = "Lorem ipsum dolor";
        require("box-elenco-altri.php");
      ?>

      <?php
        $titoloBoxAltri = "Titolo esteso del prodotto";
        require("box-elenco-altri.php");
      ?>

      <?php
        $titoloBoxAltri = "Integer tincidunt, odio id cursus viverra, elit nulla pharetra dui, condimentum aliquam leo mi viverra lorem.";
        require("box-elenco-altri.php");
      ?>

      <?php
        $titoloBoxAltri = "Integer tincidunt, odio id cursus viverra, elit nulla pharetra dui, condimentum aliquam leo mi viverra lorem.";
        require("box-elenco-altri.php");
      ?>

    </div>
  </div>
</div>
