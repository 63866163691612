import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutPaginaDettaglioComponent } from './layout-pagina-dettaglio.component';
import { NgxImageGalleryModule } from 'ngx-image-gallery';



@NgModule({
  declarations: [
    LayoutPaginaDettaglioComponent,
  ],
  imports: [
    CommonModule,
    NgxImageGalleryModule,
  ],
  exports: [
    LayoutPaginaDettaglioComponent
  ]
})
export class LayoutPaginaDettaglioModule { }
