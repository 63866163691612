import { Component, OnInit } from '@angular/core';
import { MainServiceService } from 'src/app/services/main-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  configuration: any;
  sticky: boolean = false;
  langUrl: any;

  constructor(
    public mainService: MainServiceService,
  ) {
    var header = $('.sticky-bar');
    var win = $(window);
    let that = this;
    win.on('scroll', function() {
        var scroll = win.scrollTop();
        if (scroll < 50) {
          that.sticky = false;
        } else {
          that.sticky = true;
        }
    });

  }

  ngOnInit(): void {
    this.mainService.getLangUrlSubject().subscribe(data => {
      if (data !== null) {
        this.langUrl = data;
      }
    });

    this.mainService.getConfigurationSubject().subscribe(data => {
      if (data) {
        this.configuration = data;
      }
    });
  }

  openMenu() {
    this.mainService.openMenu();
  }

  closeMenu() {
    this.mainService.closeMenu();
  }

  toggleSubmenu(id) {
    if ($("#menu_"+id).hasClass("slicknav_collapsed")) { // open
      $("#menu_"+id).removeClass("slicknav_collapsed").addClass("slicknav_open");
      $("#menu_"+id+" i.ti-plus").removeClass("ti-plus").addClass("ti-minus");
      $("#menu_"+id+" ul").removeClass("slicknav_hidden").attr("aria-hidden", "false").css("display", "");
    }
    else { // close
      $("#menu_"+id).removeClass("slicknav_open").addClass("slicknav_collapsed");
      $("#menu_"+id+" i.ti-minus").removeClass("ti-minus").addClass("ti-plus");
      $("#menu_"+id+" ul").addClass("slicknav_hidden").attr("aria-hidden", "true").css("display", "none");

    }
  }
}
