import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgxImageGalleryComponent, GALLERY_IMAGE, GALLERY_CONF } from "ngx-image-gallery";

@Component({
  selector: 'app-layout-pagina-dettaglio',
  templateUrl: './layout-pagina-dettaglio.component.html',
  styleUrls: ['./layout-pagina-dettaglio.component.css']
})
export class LayoutPaginaDettaglioComponent implements OnInit {
  @Input() item: any;

  @ViewChild(NgxImageGalleryComponent) ngxImageGallery: NgxImageGalleryComponent;

  conf: GALLERY_CONF = {
    imageOffset: '0px',
    showDeleteControl: false,
    showImageTitle: false,
  };

  images: GALLERY_IMAGE[] = [];

  constructor() { }

  ngOnInit(): void {
    if (this.item.gallery && this.item.gallery.length > 0) {
      for(let i=0; i < this.item.gallery.length; i++) {
        this.images.push({url: this.item.gallery[i].url});
      }
    }
  }

  openGallery(index: number = 0) {
    this.ngxImageGallery.open(index);
  }

  // close gallery
  closeGallery() {
    this.ngxImageGallery.close();
  }

  // set new active(visible) image in gallery
  newImage(index: number = 0) {
    this.ngxImageGallery.setActiveImage(index);
  }

  // next image in gallery
  nextImage(index: number = 0) {
    this.ngxImageGallery.next();
  }

  // prev image in gallery
  prevImage(index: number = 0) {
    this.ngxImageGallery.prev();
  }

}
