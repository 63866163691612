<div class="blog-area pt-70 blog-area-h3 p-elenco"  *ngIf="arrayItems?.length > 0">
  <div class="container">
    <div class="row mb-50" *ngFor="let item of arrayItems | keyvalue">
      <div class="col-md-8 col-12">
        <h4 [innerHTML]="item.value.titolo"></h4>
        <hp [innerHTML]="item.value.testo"></hp>
      </div>
      <div class="col-md-4 col-12">
        <a class="galleryPointer" (click)="openGallery(item.key)" >
          <img src="{{item.value.immagineElenco}}" alt="" width="100%">
        </a>
      </div>
    </div>
  </div>
<ngx-image-gallery 
[images]="images" 
[conf]="conf"
></ngx-image-gallery>
</div>
