import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContattiComponent } from './contatti.component';



@NgModule({
  declarations: [ContattiComponent],
  imports: [
    CommonModule
  ]
})
export class ContattiModule { }
