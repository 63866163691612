import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MainServiceService } from 'src/app/services/main-service.service';

@Component({
  selector: 'app-contatti',
  templateUrl: './contatti.component.html',
  styleUrls: ['./contatti.component.css']
})
export class ContattiComponent implements OnInit {
  item: any;

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private mainService: MainServiceService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };
  }

  ngOnInit(): void {
    this.mainService.getContent()
    .subscribe(item => {
      if (item.cartina != '') {
        item.cartina = this.sanitizer.bypassSecurityTrustHtml(item.cartina);
      }

      this.item = item;

      this.mainService.showLoader(false);
    });
  }

}
