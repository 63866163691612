<div class="single-portfolio-area pt-80 pb-60">
  <div class="container">
    <div class="row text-center">
      <div class="col-12">
          <h2>
              404
          </h2>
      </div>
      <div class="col-12">
          <h3>
              {{mainService.traductions?.paginaNonTrovata}}
          </h3>
      </div>
      <div class="col-12">
          <p class="text-muted">
              {{mainService.traductions?.paginaNonEsiste}}
          </p>
      </div>
    </div>
  </div>
</div>
