import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginaGenericaComponent } from './pagina-generica.component';
import { LayoutPaginaDettaglioModule } from 'src/app/modules/layout-pagina-dettaglio/layout-pagina-dettaglio.module';



@NgModule({
  declarations: [
    PaginaGenericaComponent,
  ],
  imports: [
    CommonModule,
    LayoutPaginaDettaglioModule
  ],
  exports: [
    PaginaGenericaComponent
  ]
})
export class PaginaGenericaModule { }
