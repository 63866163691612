import { Component, OnInit } from '@angular/core';
import { PaginaConvenzioniService } from './pagina-convenzioni.service';
import { MainServiceService } from 'src/app/services/main-service.service';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-pagina-convenzioni',
  templateUrl: './pagina-convenzioni.component.html',
  styleUrls: ['./pagina-convenzioni.component.css']
})
export class PaginaConvenzioniComponent implements OnInit {
  arrayItems: any[];
  traduzioni: any;

  constructor(
    private service: PaginaConvenzioniService,
    public mainService: MainServiceService,
  ) { }

  ngOnInit(): void {
    this.mainService.showLoader(true);

    forkJoin([
      this.service.getElenco(),
      this.mainService.getTraductionsSubject().pipe(first(data => data != null))
    ])
    .subscribe(
      ([data, traduzioni]) => {
        this.traduzioni = traduzioni;

        this.arrayItems = data.items;
        this.mainService.showLoader(false);
     });
  }
}
