import { HttpClient } from '@angular/common/http';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer, Meta, SafeResourceUrl, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class MainServiceService {
  public readonly SERVER_URL: string = "https://www.palazzocesi-acquasparta.it/webserver.php?request";
  public traductionsObject: BehaviorSubject<any>;
  public supportedLanguages: string[] = ["ita", "eng"];
  public language: string;
  public traductions: any;
  public sectionId: number;
  public categoryId: number;
  public detailId: number;
  public header: any;
  public urlArray: string[];
  public sectionName: string;
  public categoryName: string;
  public detailName: string;
  public paginationIndex: number = 1;
  public configuration: any;
  public configurationObject: BehaviorSubject<any>;
  public langUrlObject: BehaviorSubject<any[]>;

  emailPattern = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$';
  numberPatter = '^[0-9]*$';
  datePattern = "^(19|20)\\d\\d([- /.])(0[1-9]|1[012])\\2(0[1-9]|[12][0-9]|3[01])$";

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private metaTagService: Meta,
    private titleService: Title,
    private router: Router

  ) {
    this.traductionsObject = new BehaviorSubject<any>(null);
    this.configurationObject = new BehaviorSubject<any>(null);
    this.langUrlObject = new BehaviorSubject<any[]>(null);
  }

  public getConfiguration(): Observable<any> {
    let requestUrl = this.SERVER_URL + "&action=getConfiguration";

    return this.http.get<any>(requestUrl);
  }

  getConfigurationSubject(): Observable<any> {
    return this.configurationObject.asObservable();
  }

  setConfigurationSubject(data: any): void {
    this.configurationObject.next(data);
  }


// Language

  public getLocalStorageLanguage(): string {
    return localStorage.getItem("language");
  }

  public setLocalStorageLanguage(language: string): void {
    localStorage.setItem("language", language);
  }

  public restoreLocalStorageLanguage(): string {
    let language: string = this.supportedLanguages[0];

    this.setLocalStorageLanguage(language);

    return language;
  }

  public isLanguageSupported(language: string): boolean {
    return this.supportedLanguages.includes(language);
  }

  public validateLocalStorageLanguage(): string {
    let language: string = this.getLocalStorageLanguage();

    if(!this.isLanguageSupported(language))
      language = this.restoreLocalStorageLanguage();

    return language;
  }

  // Traduzioni

  public getTraductions(): Observable<any> {
    let requestUrl = this.SERVER_URL + "&action=getTraduzioni&lang=" + this.language;

    return this.http.get<any>(requestUrl);
  }

  public updateTraductions(): void {
    this.getTraductions()
    .subscribe(
      data => {
        this.traductions = data;
        this.traductionsObject.next(data);
      }
    );
  }

  public getTraductionsSubject(): Observable<any> {
    return this.traductionsObject.asObservable();
  }

  public setTraductionsSubject(data: any): void {
    this.traductionsObject.next(data);
  }

  // Breadcrumbs

  public getBreadcrumbs(): Observable<any> {
    let requestUrl = this.SERVER_URL + "&action=getBreadcrumbs&lang=" + this.language + "&id=" + this.sectionId + "&idCategoria=" + this.categoryId + "&idDettaglio=" + this.detailId;

    return this.http.get<any>(requestUrl);
  }

  public updateBreadcrumbs(event: NavigationEnd): void {
    this.getBreadcrumbs()
    .subscribe(
      data => {
        this.header = data;

        this.updateSEOMeta();
      }
    );
  }

  // SEO

  public updateSEOMeta(): void {
    this.titleService.setTitle(this.header.metaSEO.title);

    this.metaTagService.updateTag({name: "keywords", content: this.header.metaSEO?.keywordsSEO});
    this.metaTagService.updateTag({name: "description", content: this.header.metaSEO?.description});
    this.metaTagService.updateTag({name: "author", content: this.header.metaSEO?.author});

    this.metaTagService.updateTag({property: "og:title", content: this.header.metaSEO?.titleSocial});
    this.metaTagService.updateTag({property: "og:description", content: this.header.metaSEO?.descriptionSocial});
    this.metaTagService.updateTag({property: "og:image", content: this.header.metaSEO?.imageSocial});
    this.metaTagService.updateTag({property: "og:image:width", content: this.header.metaSEO?.imageW});
    this.metaTagService.updateTag({property: "og:image:height", content: this.header.metaSEO?.imageH});
    this.metaTagService.updateTag({property: "og:locale", content: this.header.metaSEO?.locale});
    this.metaTagService.updateTag({property: "og:site_name", content: this.header.metaSEO?.siteName});
    this.metaTagService.updateTag({property: "og:type", content: this.header.metaSEO?.type});
    this.metaTagService.updateTag({property: "twitter:title", content: this.header.metaSEO?.titleSocial});
    this.metaTagService.updateTag({property: "twitter:description", content: this.header.metaSEO?.descriptionSocial});
    this.metaTagService.updateTag({property: "twitter:image", content: this.header.metaSEO?.imageSocial});
    this.metaTagService.updateTag({property: "twitter:card", content: this.header.metaSEO?.twitterCard});
  }

  // Content

  public getContent(id?: number): Observable<any> {
    let requestUrl = this.SERVER_URL + "&action=getContenuto&lang=" + this.language + "&id=" + (id ?? this.sectionId);

    return this.http.get<any>(requestUrl);
  }

  // Home page

  public getHomePage(): Observable<any> {
    let requestUrl = this.SERVER_URL + "&action=getHomePage&lang=" + this.language;

    return this.http.get<any>(requestUrl);
  }

  // Elenco

  public getElenco(): Observable<any> {
    let requestUrl = this.SERVER_URL + "&action=getElenco&lang=" + this.language + "&idSection=" + this.sectionId;

    return this.http.get<any>(requestUrl);
  }

  // Elemento

  public getElemento(): Observable<any> {
    let requestUrl = this.SERVER_URL + "&action=getElemento&lang=" + this.language + "&idSection=" + this.sectionId + "&id=" + this.detailId;

    return this.http.get<any>(requestUrl);
  }

  // Social networks

  public getSocialNetworks(): Observable<any> {
    let requestUrl = this.SERVER_URL + "&action=getElencoSocial&lang=" + this.language;

    return this.http.get<any>(requestUrl);
  }

  // Url params

  public updateUrlParams(event: NavigationEnd): void {
    // Url array
    this.urlArray = event.url.split("/");

    // Language
    let language: string = this.isLanguageSupported(this.urlArray[1])?
      this.urlArray[1]:
      this.validateLocalStorageLanguage();

    // Section
    let sectionId: number = +this.urlArray[2] || 0;
    let sectionName: string = this.urlArray[3] || "home";

    // Category
    let categoryId: number = 0;
    let categoryName: string = "";

    // Detail
    let detailId: number = 0;
    let detailName: string = "";

    if(this.urlArray[4] == "C") {
      categoryId = +this.urlArray[5] || categoryId;
      categoryName = this.urlArray[6] || categoryName;

      detailId = +this.urlArray[7] || detailId;
      detailName = this.urlArray[8] || detailName;
    }
    else {
      detailId = +this.urlArray[4] || detailId;
      detailName = this.urlArray[5] || detailName;
    }

    // Traductions
    let isUpdateTraductions: boolean = false;

    // Breadcrumbs
    let isUpdateBreadcrumbs: boolean = false;

    if(language != this.language) {
      this.language = language;
      this.setLocalStorageLanguage(this.language);

      isUpdateTraductions = true;
      isUpdateBreadcrumbs = true;
    }

    if(sectionId != this.sectionId || categoryId != this.categoryId || detailId != this.detailId) {
      if(sectionId != this.sectionId || categoryId != this.categoryId) {
        this.paginationIndex = 1;
      }

      this.sectionId = sectionId;
      this.sectionName = sectionName;

      this.categoryId = categoryId;
      this.categoryName = categoryName;

      this.detailId = detailId;
      this.detailName = detailName;

      isUpdateBreadcrumbs = true;
    }

    if(isUpdateTraductions)
      this.updateTraductions();

    if(isUpdateBreadcrumbs)
      this.updateBreadcrumbs(event);

      var actUrl = this.router.url;
      if (actUrl == "/") {
        actUrl = "/" + this.language + "/0/homepage";
      }

      let langUrl: any = {};
      langUrl.ita = actUrl.replace("/" + this.language + "/", "/ita/");
      langUrl.eng = actUrl.replace("/" + this.language + "/", "/eng/");

      this.setLangUrlSubject(langUrl);
  }

  getLangUrlSubject(): Observable<any> {
    return this.langUrlObject.asObservable();
  }

  setLangUrlSubject(data: any): void {
    this.langUrlObject.next(data);
  }


  // Utils

  public sanitizeURL(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public getVideoUrl(videoCode: string) {
    // tslint:disable-next-line: max-line-length
    const text = this.sanitizer.bypassSecurityTrustHtml('<iframe src="https://www.youtube.com/embed/' + videoCode + '?rel=0" width="100%" height="100%" allowfullscreen></iframe>');

    return text;
  }

  showLoader(show: boolean): void {
    if (show) {
      $('#loaderSpinner').css('display', 'block');
    } else {
      $('#loaderSpinner').css('display', 'none');
    }
  }

  openMenu() {
    $(".slicknav_btn").removeClass("slicknav_collapsed").addClass("slicknav_open");
    $(".slicknav_nav").removeClass("slicknav_hidden").attr("aria-hidden", "false").css("display", "");
  }

  closeMenu() {
    $(".slicknav_btn").removeClass("slicknav_open").addClass("slicknav_collapsed");
    $(".slicknav_nav").addClass("slicknav_hidden").attr("aria-hidden", "true").css("display", "none");
  }


  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
