import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GalleriaFotoComponent } from './galleria-foto/galleria-foto.component';
import { NgxImageGalleryModule } from 'ngx-image-gallery';
import { GalleriaVideoComponent } from './galleria-video/galleria-video.component';



@NgModule({
  declarations: [GalleriaFotoComponent, GalleriaVideoComponent],
  imports: [
    CommonModule,
    NgxImageGalleryModule
  ]
})
export class MultimediaModule { }
