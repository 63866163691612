<div class="p-dettaglio pt-70 pb-70" *ngIf="item">
  <div class="about-area">
      <div class="container">
          <div class="row">
              <div class="col-sm-12" *ngIf="item.immagine">
                  <div class="about-us-wrap">
                      <img src="{{item.immagine}}" width="100%" alt="">
                  </div>
              </div>
              <div class="col-sm-12 mt-15" *ngIf="item.categoria || item.data">
                <div class="row">
                  <div class="col-6 blog-meta-2" style="text-align: left;">
                      {{item.categoria}}
                  </div>
                  <div class="col-6 blog-meta-2" style="text-align: right;">
                    {{item.data}}
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                  <div class="tools-content mt-20">
                    <p [innerHTML]="item.testo"></p>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <div class="photogallery-area py-70 mt-30" *ngIf="item.gallery?.length > 0">
      <div class="container">
          <div class="row">
              <div class="col-lg-6 col-md-8">
                  <div class="section-title-2 blog-sec-title2">
                      <h3>Photogallery</h3>
                  </div>
              </div>
          </div>
          <div class="row">
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6 mb-30" *ngFor="let foto of item.gallery; let i = index">
                  <a class="galleryPointer" (click)="openGallery(i)">
                      <img [src]="foto.file" alt="..." class="img-gallery">
                  </a>
              </div>
          </div>
      </div>

      <ngx-image-gallery
      [images]="images"
      [conf]="conf"
      ></ngx-image-gallery>
  </div>
</div>
