import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MainServiceService } from './services/main-service.service';
import { NotificationsService } from './services/notification.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'palazzocesi-acquasparta-it';

  constructor(
    private router: Router,
    private notificationsService: NotificationsService,
    public mainService: MainServiceService,
  ) { }

  ngOnInit(): void {
    this.mainService.getConfiguration()
    .subscribe(
      data => {
        this.mainService.setConfigurationSubject(data);
      }
    );

    this.router.events
    .subscribe(
      event => {
        if(event instanceof NavigationEnd) {
          this.mainService.updateUrlParams(event);
          this.mainService.closeMenu();
        }
      }
    );
  }


  /* private loadGoogleAnalytics(trackingID: string): void {
    let gaScript = document.createElement("script");
    gaScript.setAttribute("async", "true");
    gaScript.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=" + trackingID);

    let gaScript2 = document.createElement("script");
    gaScript2.innerText = "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '" + trackingID + "');";

    document.documentElement.firstChild.appendChild(gaScript);
    document.documentElement.firstChild.appendChild(gaScript2);
  } */

}



