import { Component, OnInit, ViewChild } from '@angular/core';
import { MainServiceService } from 'src/app/services/main-service.service';
import { forkJoin } from 'rxjs';
import { MultimediaService } from '../multimedia.service';
import { NgxImageGalleryComponent, GALLERY_IMAGE, GALLERY_CONF } from "ngx-image-gallery";
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-galleria-foto',
  templateUrl: './galleria-foto.component.html',
  styleUrls: ['./galleria-foto.component.css']
})
export class GalleriaFotoComponent implements OnInit {
  @ViewChild(NgxImageGalleryComponent) ngxImageGallery: NgxImageGalleryComponent;

  arrayItems: any[];
  traduzioni: any;

  conf: GALLERY_CONF = {
    imageOffset: '0px',
    showDeleteControl: false,
    showImageTitle: false,
  };

  images: GALLERY_IMAGE[] = [];

  constructor(
    private service: MultimediaService,
    private router: Router,
    public mainService: MainServiceService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };
  }

  ngOnInit(): void {
    forkJoin([
      this.service.getElencoAlbum(),
      this.mainService.getTraductionsSubject().pipe(first(data => data != null))
    ])
    .subscribe(
      ([items, traduzioni]) => {
        this.traduzioni = traduzioni;
        this.arrayItems = items;
        this.mainService.showLoader(false);
     });
  }

  openGallery(idAlbum) {
    this.images = [];
    forkJoin([
      this.service.getAlbum(idAlbum),
    ])
    .subscribe(
      ([items]) => {
        if (items.length > 0) {
          for(let i=0; i < items.length; i++) {
            this.images.push({url: items[i]});
          }
        }

        this.ngxImageGallery.open(0);
     });

  }
}
