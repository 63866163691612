<div class="single-portfolio-area pt-80 mTicket" *ngIf="traduzioni">
  <div class="container">
    <div class="row pb-20">
      <div class="col-12">
        <div class="single-portfolio-wrap">
          <div *ngIf="step == 1" class="boxRecuperoQrcode pointer" [routerLink]="'/' + mainService.language + '/32/' + traduzioni.sezioni[32].titoloUrl + '/qrcode'">
            <h4 [innerHTML]="traduzioni.messaggioRecuperoQrCode1"></h4>
            <div [innerHTML]="traduzioni.messaggioRecuperoQrCode2"></div>
          </div>
          <div class="single-port-content" *ngIf="step > 0 && step < 5">
            <div class="single-port-title">
              <h3 [innerHTML]="traduzioni.acquistaOraBiglietto"></h3>
            </div>
          </div>
          <div class="row mb-50">
            <div class="col-12" style="font-size: 18px;">
              Attualmente non è disponibile la prenotazione online, stiamo aggiornando le fasce orarie stagionali e la nuova esperienza nella sala immersiva.<br>È possibile acquistare i ticket presso la biglietteria nel bookshop di Palazzo Cesi.
            </div>
          </div>
          <div *ngIf="step == 1">
            <div class="row" >
              <div class="col-12">
                <form>
                  <div class="row">
                    <div class="form-group pb-20 col-12 col-sm-6" [formGroup]="myForm">
                      <label for="">{{traduzioni.quandoVuoiVenire}}?</label>
                      <input type="date" [min]="today" class="form-control" formControlName="data" disabled >
                    </div>
                    <div class="form-group pb-20 col-12 col-sm-6" [formGroup]="myForm">
                      <label for="">{{traduzioni.quantiSiete}}?</label>
                      <input type="number" class="form-control" formControlName="persone" disabled >
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="row" >
              <div class="col-xl-8">
                <div class="single-shortcode-btn black-button medium-button mb-30">
                  <a class="btn-hover noHover" disabled [innerHTML]="traduzioni.verificaDisponibilita" ></a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
