<div class="blog-area pt-70 blog-area-h3 p-elenco"  *ngIf="arrayItems?.length > 0 && 0">
  <div class="container">
    <div class="row mb-50" *ngFor="let item of arrayItems | keyvalue">
      <div class="col-md-8 col-12">
        <h4 [innerHTML]="item.value.titolo"></h4>
        <hp [innerHTML]="item.value.testo"></hp>
      </div>
      <div class="col-md-4 col-12">
        <img src="{{item.value.immagine}}" alt="" width="100%">
      </div>
    </div>
  </div>
</div>

<div class="blog-area pt-70 blog-area-h3 p-elenco"  *ngIf="arrayItems?.length > 0">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6" *ngFor="let item of arrayItems">
        <div class="blog-wrap-2 mb-50 zoom-hover">
          <div class="blog-img">
              <img src="{{item.immagine}}" alt="">
          </div>
          <div class="blog-conent-2">
              <h3>{{item.titolo}}</h3>
              <hp [innerHTML]="item.testo"></hp>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
