  <!-- breadcrumb area -->
  <div class="breadcrumb-area bg-img pt-230 pb-152 default-overlay-2" style="background-image: url(assets/img/testatina.jpg);">
    <div class="container">
        <div class="breadcrumb-content text-center" *ngIf="mainService.header">
            <h2>
              {{mainService.header.testataSezione.riga1}}
            </h2>
            <ul>
              <li *ngFor="let item of mainService.header.breadCrumbs; last as isLast"
              [className]="isLast ? 'active' : ''">
                <ng-container *ngIf="(item.url != '') && !isLast; else noRoute" >
                  <a [href]="[item.url]">{{item.denominazione}}</a>
                </ng-container>

                  <ng-template #noRoute>{{item.denominazione}}</ng-template>
              </li>
            </ul>
        </div>
    </div>
</div>
