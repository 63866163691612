import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-elenco-accoglienza',
  templateUrl: './elenco-accoglienza.component.html',
  styleUrls: ['./elenco-accoglienza.component.css']
})
export class ElencoAccoglienzaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
