import { Component, OnInit } from '@angular/core';
import { MainServiceService } from 'src/app/services/main-service.service';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { MShopService } from '../m-shop.service';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { first } from 'rxjs/operators';
import { NotificationsService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-m-shop-scheda',
  templateUrl: './m-shop-scheda.component.html',
  styleUrls: ['./m-shop-scheda.component.css']
})
export class MShopSchedaComponent implements OnInit {
  myItem: any;
  traduzioni: any;
  quantitaValida: boolean = true;
  totaleProdotto: string = "";
  speseSpedizione: string = "";
  importoTotale: string = "";
  buttonReady: boolean = false;

  public payPalConfig?: IPayPalConfig;

  constructor(
    private service: MShopService,
    private router: Router,
    public mainService: MainServiceService,
    private notificationsService: NotificationsService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };
  }

  ngOnInit(): void {
    forkJoin([
      this.service.getDettaglio(this.mainService.detailId),
      this.mainService.getTraductionsSubject().pipe(first(data => data != null))
    ])
    .subscribe(
      ([item, traduzioni]) => {
        this.traduzioni = traduzioni;
        this.myItem = item;
        this.calcolaPrezzo(1);
        this.mainService.showLoader(false);
      });
  }

  onChangeQuantity(event) {
    let quantita = event.srcElement.value;
    this.buttonReady = false;

    if ((quantita == 0) || (quantita == '')) {
      this.quantitaValida = false;
    }
    else {
      this.quantitaValida = true;
      this.calcolaPrezzo(quantita);
    }
  }


  calcolaPrezzo(quantita) {
    this.totaleProdotto = (this.myItem.prezzo * quantita).toFixed(2).replace(".", ",");
    this.speseSpedizione = this.myItem.speseSpedizioneTxt;
    this.importoTotale = (this.myItem.prezzo * quantita + parseInt(this.myItem.speseSpedizione)).toFixed(2).replace(".", ",");

    this.payPalConfig = null;
    this.initPaypalButton(quantita);
  }


  private initPaypalButton(quantita): void {
    var totale = (quantita * this.myItem.prezzo + parseFloat(this.myItem.speseSpedizione));

    this.payPalConfig = {
      currency: 'EUR',
      clientId: this.myItem.clientId,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [{
          amount: {
              currency_code: 'EUR',
              value: String(totale),
              breakdown: {
                  item_total: {
                      currency_code: 'EUR',
                      value: String(totale)
                  }
              }
          },
          items: [{
              name: this.myItem.titolo,
              quantity: String(quantita),
              unit_amount: {
                  currency_code: 'EUR',
                  value: String(this.myItem.prezzo),
              },
          },
          {
            name: this.traduzioni.speseSpedizione,
            quantity: "1",
            unit_amount: {
                currency_code: 'EUR',
                value: String(this.myItem.speseSpedizione),
            },
        }
        ]
      }]
      },
      advanced: {
        commit: 'true',
      },
      style: {
        label: 'pay',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        actions.order.get().then(details => {
        });
      },
      onClientAuthorization: (data) => {
        console.log(data);
        const additionalInfo = {'idItem' : this.myItem.id, 'prezzo' : this.myItem.prezzo, 'quantita' : quantita, 'speseSpedizione' : this.myItem.speseSpedizione};
        this.finalizePayment(data, additionalInfo);

      },
      onCancel: (data, actions) => {
        this.mainService.showLoader(false);
      },
      onError: err => {
        this.mainService.showLoader(false);
      },
      onClick: (data, actions) => {
        if (data.fundingSource !== 'card') { // il pagamento con carta non avviene su finestra modale per cui non devo bloccare la finestra
          this.mainService.showLoader(true);
        }
      },
    };
    this.buttonReady = true;
  }


  finalizePayment(data, paymentData) {
    this.service.finalizePayment(data, paymentData)
    .pipe(first())
    .subscribe(
      data => {
        this.mainService.showLoader(false);
        this.notificationsService.show('success', 'Pagamento effettuato con successo', 'Riceverai al tuo indirizzo email il riassunto del tuo acquisto');
      },
      error => {
        this.mainService.showLoader(true);
        let errorText = error.error.message ? error.error.message : "Errore non gestito durante l'operazione.<br>Prima di riprovare verificare se l'addebito è andato a buon fine";
        this.notificationsService.show('error', '', errorText);
      }
    )
  }
}
