import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import * as bootstrap from "bootstrap";
import * as $ from 'jquery';
import 'hammerjs';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HeaderModule } from './modules/header/header.module';
import { FooterModule } from './modules/footer/footer.module';
import { LoaderModule } from './modules/loader/loader.module';
import { HomePageModule } from './pages/home-page/home-page.module';
import { ErrorInterceptorService } from './services/error-interceptor.service';
import { TestatinaModule } from './modules/testatina/testatina.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PaginaGenericaModule } from './pages/pagina-generica/pagina-generica.module';
import { RouterModule } from '@angular/router';
import { SafePipe } from './services/safe.pipe';
import { MultimediaModule } from './pages/multimedia/multimedia.module';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ContattiModule } from './pages/contatti/contatti.module';
import { ElencoGenericoModule } from './pages/elenco-generico/elenco-generico.module';
import { LayoutPaginaDettaglioModule } from './modules/layout-pagina-dettaglio/layout-pagina-dettaglio.module';
import { MTicketMShopModule } from './pages/m-ticket-m-shop/m-ticket-m-shop.module';
import { PaginaSaleComponent } from './pages/pagina-sale/pagina-sale.component';
import { PaginaSaleModule } from './pages/pagina-sale/pagina-sale.module';
import { PaginaConvenzioniModule } from './pages/pagina-convenzioni/pagina-convenzioni.module';

@NgModule({
  declarations: [
    AppComponent,
    SafePipe,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    HeaderModule,
    FooterModule,
    LoaderModule,
    HomePageModule,
    PaginaGenericaModule,
    ContattiModule,
    TestatinaModule,
    MultimediaModule,
    ElencoGenericoModule,
    LayoutPaginaDettaglioModule,
    MTicketMShopModule,
    NgbModule,
    PaginaSaleModule,
    PaginaConvenzioniModule
  ],
  exports: [
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
