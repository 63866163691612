import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MainServiceService } from 'src/app/services/main-service.service';

@Injectable({
  providedIn: 'root'
})
export class HomePageService {

  constructor(
    private mainService: MainServiceService,
    private http: HttpClient,
  ) { }

  public getHomePage(): Observable<any> {
    let requestUrl = this.mainService.SERVER_URL + "&action=getHomePage&lang=" + this.mainService.language;

    return this.http.get<any>(requestUrl);
  }
}
