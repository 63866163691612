<div class="contact-area pt-70 pb-70 contatti" *ngIf="item">
  <div class="container">
      <div class="row">
          <div class="col-lg-6">
              <div class="contact-info-area">
                  <div class="contact-info-wrap">
                      <div class="single-contact-info" *ngIf="item?.telefono != ''">
                        <div class="contact-info-icon">
                            <i class="ti-desktop"></i>
                        </div>
                        <div class="contact-info-content">
                            <p>{{item.telefono}}</p>
                        </div>
                      </div>
                      <div class="single-contact-info" *ngIf="item?.cellulare != ''">
                          <div class="contact-info-icon">
                              <i class="ti-mobile"></i>
                          </div>
                          <div class="contact-info-content">
                              <p>{{item.cellulare}}</p>
                          </div>
                      </div>
                      <div class="single-contact-info" *ngIf="item?.email != ''">
                          <div class="contact-info-icon">
                              <i class="ti-email"></i>
                          </div>
                          <div class="contact-info-content">
                              <p><a href="mailto:{{item.email}}">{{item.email}}</a></p>
                          </div>
                      </div>
                      <div class="single-contact-info" *ngIf="item?.indirizzo != ''">
                          <div class="contact-info-icon">
                              <i class="ti-map-alt"></i>
                          </div>
                          <div class="contact-info-content">
                              <p>{{item.indirizzo}}</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-lg-6" *ngIf="item.cartina != ''">
              <div class="contact-map pl-10 map-mrg" [innerHTML]="item.cartina">
              </div>
          </div>
      </div>
  </div>
</div>

