import { Component, OnInit } from '@angular/core';
import { ElencoGenericoService } from '../elenco-generico.service';
import { Router } from '@angular/router';
import { MainServiceService } from 'src/app/services/main-service.service';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-elenco',
  templateUrl: './elenco.component.html',
  styleUrls: ['./elenco.component.css']
})
export class ElencoComponent implements OnInit {
  arrayItems: any[];
  traduzioni: any;
  arrayCategorie: any[];

  constructor(
    private service: ElencoGenericoService,
    private router: Router,
    public mainService: MainServiceService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };
  }

  ngOnInit(): void {
    this.mainService.showLoader(true);

    forkJoin([
      this.service.getElenco(),
      this.mainService.getTraductionsSubject().pipe(first(data => data != null))
    ])
    .subscribe(
      ([data, traduzioni]) => {
        this.traduzioni = traduzioni;

        for(let i=0; i < data.items.length; i++) {
          data.items[i].routerLink = '/' + this.mainService.language + '/' + this.mainService.sectionId + '/' + this.traduzioni.sezioni[this.mainService.sectionId].titoloUrl + '/' + data.items[i].id + '/' + data.items[i].titoloUrl;
        }
        this.arrayItems = data.items;
        this.arrayCategorie = data.categorie ?? [];
        this.mainService.showLoader(false);
     });
  }

}
