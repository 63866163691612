import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { MainServiceService } from 'src/app/services/main-service.service';
import { first } from 'rxjs/operators';
import { MTicketService } from '../m-ticket.service';
import { NotificationsService } from 'src/app/services/notification.service';
import { TestatinaComponent } from 'src/app/modules/testatina/testatina.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-m-ticket-qr-code',
  templateUrl: './m-ticket-qr-code.component.html',
  styleUrls: ['./m-ticket-qr-code.component.css']
})
export class MTicketQrCodeComponent implements OnInit {
  recoverForm: FormGroup;
  traduzioni: any;
  codicePrenotazionePattern = '^[A-Za-z]{6}-[0-9]{3,}$';
  loading = false;
  datiPrenotazione: any = null;

  constructor(
    public mainService: MainServiceService,
    private formBuilder: FormBuilder,
    private service: MTicketService,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(
      (params) => {
        if ((params.email) && (params.codicePrenotazione)) {
          this.recuperaQrCode(params.email, params.codicePrenotazione);
        }
      }
    );

    forkJoin([
      this.mainService.getTraductionsSubject().pipe(first(data => data != null))
    ])
    .subscribe(
      ([traduzioni]) => {
        this.traduzioni = traduzioni;

        this.recoverForm = this.formBuilder.group({
          email: ['', [Validators.required, Validators.pattern(this.mainService.emailPattern)]],
          codicePrenotazione: ['', [Validators.required, Validators.pattern(this.codicePrenotazionePattern)]],
        });

        this.mainService.showLoader(false);
      });
  }

  get objInputEmail() {
    return this.recoverForm.get('email');
  }

  get objInputCodice() {
    return this.recoverForm.get('codicePrenotazione');
  }


  recuperaQrCode(email = null, codicePrenotazione = null) {
    if (this.loading) {
      return;
    }

    let params = null;
    if (email) {
      params = {email, codicePrenotazione};
    }
    else {
      if (!this.recoverForm.valid) {
        this.mainService.validateAllFormFields(this.recoverForm);
        return false;
      }
      params = this.recoverForm.value;
    }
    this.mainService.showLoader(true);

    this.service.recuperaQrCode(params)
    .pipe(first())
    .subscribe(
      data => {
        this.datiPrenotazione = data;

        this.mainService.showLoader(false);
      },
      error => {
        this.loading = false;
        this.mainService.showLoader(false);
        this.notificationsService.show('error', '', error.error.message);
      }
    )


  }

}
