import { Component, OnInit, ViewChild } from '@angular/core';
import { HomePageService } from './home-page.service';
import { MainServiceService } from 'src/app/services/main-service.service';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { NgxImageGalleryComponent, GALLERY_IMAGE, GALLERY_CONF } from "ngx-image-gallery";


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {
  @ViewChild(NgxImageGalleryComponent) ngxImageGallery: NgxImageGalleryComponent;

  public homePage: any;
  traduzioni: any;

  conf: GALLERY_CONF = {
    imageOffset: '0px',
    showDeleteControl: false,
    showImageTitle: false,
  };

  images: GALLERY_IMAGE[] = [];

  constructor(
    private service: HomePageService,
    private router: Router,
    public mainService: MainServiceService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };
  }

  ngOnInit(): void {
    forkJoin([
      this.service.getHomePage(),
      this.mainService.getTraductionsSubject().pipe(first(data => data != null))
    ])
    .subscribe(
      ([homePage, traduzioni]) => {
        this.traduzioni = traduzioni;

        for(let i=0; i < homePage.percorsiEsperienze.items.length; i++) {
          homePage.percorsiEsperienze.items[i].routerLink = '/' + this.mainService.language + '/' + homePage.percorsiEsperienze.items[i].id + '/' + this.traduzioni.sezioni[homePage.percorsiEsperienze.items[i].id].titoloUrl;
        }

        if (homePage.photogallery.all.length > 0) {
          for(let i=0; i < homePage.photogallery.all.length; i++) {
            this.images.push({url: homePage.photogallery.all[i]});
          }
        }


        this.homePage = homePage
        this.mainService.showLoader(false);
     });
  }

  openGallery(index: number = 0) {
    this.ngxImageGallery.open(index);
  }

}
