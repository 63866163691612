import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestatinaComponent } from './testatina.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [TestatinaComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    TestatinaComponent
  ]

})
export class TestatinaModule { }
